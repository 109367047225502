<template>
  <BContainer class="vw-my-sessions mt-4">
    <BRow>
      <BCol v-for="session of sessions" :key="session.key" sm="12" md="6">
        <BCard>
          <OValue variant="rows" :label="$t(`fields.created`)">
            {{ session.created | dateTime('long') }}
          </OValue>
          <OValue variant="rows" class="mt-2" :label="$t(`fields.user-agent`)">
            {{ session.userAgent }}
          </OValue>
          <OValue variant="rows" class="mt-2" :label="$t(`fields.fingerprint`)">
            {{ session.fingerprint }}
          </OValue>
        </BCard>
      </BCol>
    </BRow>
    <BAlert variant="danger" show class="mt-4">
      <p>{{ $t(`${prefix}.cleanup.description`) }}</p>
      <BButton data-identity="vw-my-sessions-button-clear-all" variant="danger" @click="onClickClearAll">
        {{ $t(`${prefix}.cleanup.button-label`) }}
      </BButton>
    </BAlert>
  </BContainer>
</template>

<script>
import { pageMixin } from 'library/components/src/plugins/page/mixin/page.mixin';
import { currentUserMixin } from 'library/components/src/plugins/auth/mixin/current-user.mixin';
import { BAlert, BCard, BCardGroup, BCol, BContainer, BRow } from 'bootstrap-vue';
import { dateTime } from 'library/components/src/tools/filters/date-time';
import OValue from '../../o/value';

const prefix = 'vw.my-sessions';

export default {
  mixins: [pageMixin, currentUserMixin],
  filters: { dateTime },
  components: {
    BContainer,
    BCard,
    BCardGroup,
    BRow,
    BCol,
    OValue,
    BAlert,
  },
  data() {
    return {
      sessions: [],
    };
  },
  computed: {
    prefix: () => prefix,
  },

  // hooks
  beforeMount() {
    this.setPageParams({
      sectionGroup: this.$t(`${prefix}.page.group`),
      sectionTitle: this.$t(`${prefix}.page.title`),
    });

    this.fetch();
  },

  methods: {
    fetch() {
      this.$api.auth.sessions().then(sessions => (this.sessions = sessions));
    },
    async onClickClearAll() {
      await this.$api.auth.cleanup();
      this.toLogout();
    },
  },
};
</script>
